import React from 'react'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'
import { GatsbyImage } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import {MDBRow, MDBCol, MDBContainer} from 'mdbreact'
import Layout from '../components/layoutNewsItem'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import EventSection from '../components/eventSection'
import { StickyShareButtons } from 'sharethis-reactjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/pro-regular-svg-icons'
import { faBan } from '@fortawesome/pro-solid-svg-icons'
import get from 'lodash/get'
import moment from 'moment'

function filterByPublishTo(arr, publishTo) {
  return arr.filter(obj =>
    obj.publishTo.some(publish => publish.url === publishTo)
  )
}

class EventItemTemplate extends React.Component {
  render() {
    const event = get(this.props, 'data.contentfulEvent')
    const heroImage = get(this.props, 'data.heroImage')
    const siteUrl = get(this.props, 'data.site.siteMetadata.siteUrl')
    const pageUrl = siteUrl + '/events/' + event.slug + '/'

    return (
      <>
        <Layout location={this.props.location}>
          <div id="sub-page">
            <Hero
              class="intro-65"
              image={ event.hero ? event.hero.localFile.childImageSharp.gatsbyImageData : heroImage?.childImageSharp?.gatsbyImageData }
              alt={event.hero ? event.title.toLowerCase() : "event conference"}
              title={event.title}
              subtitle={ event.subtitle + '<br /><br />' + moment(event.date).format('MMMM DD, YYYY - HH:mm') + ' (' + event.timezone + ')' }
              type="root" /* change this to event */
            />
          </div>

          <div className="d-none d-sm-block">
            <StickyShareButtons
              config={{
                alignment: 'right', // alignment of buttons (left, right)
                color: 'social', // set the color of buttons (social, white)
                enabled: true, // show/hide buttons (true, false)
                font_size: 16, // font size for the buttons
                hide_desktop: false, // hide buttons on desktop (true, false)
                labels: 'null', // button labels (cta, counts, null)
                language: 'en', // which language to use (see LANGUAGES)
                networks: [
                  // which networks to include (see SHARING NETWORKS)
                  'twitter',
                  'linkedin',
                  'facebook',
                  'whatsapp',
                  'email',  
                ],
                padding: 12, // padding within buttons (INTEGER)
                show_mobile: false, // show/hide the buttons on mobile (true, false)
                show_toggle: false, // show/hide the toggle buttons (true, false)
                size: 48, // the size of each button (INTEGER)
                top: 85, // offset in pixels from the top of the page

                // OPTIONAL PARAMETERS
                url: pageUrl, // (defaults to current url)
                description: event.title, // (defaults to og:description or twitter:description)
                title: event.title, // (defaults to og:title or twitter:title)
              }}
            />
          </div>
          <section className="bg-white">
            <MDBContainer>
              <MDBRow>
                <MDBCol lg={event.image ? '7' : '12'} md="12">
                  <h2 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-large"> Event details </h2>
                  <h3 className="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium"> Duration: {event.duration} </h3>
                  <div>
                  {renderRichText(event.body, {
                      renderNode: {
                        [BLOCKS.PARAGRAPH]: (node, children) => (
                          <p 
                            className="font-w-400 text-medium" 
                            style={{ whiteSpace: 'pre-wrap' }} 
                            > 
                            {children} 
                          </p>
                        ),
                        [BLOCKS.HEADING_3]: (node, children) => (
                          <h3 
                            className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-2" 
                            style={{ whiteSpace: 'pre-wrap' }} 
                            >
                              {children} 
                            </h3>
                        ),
                        [INLINES.HYPERLINK]: (node, children) =>
                          node.data.uri.charAt(0) == '/' ? (
                            <Link to={node.data.uri}>{children}</Link>
                          ) : (
                            <a href={node.data.uri} className="effect" target="_blank" > {children} </a>
                          ),
                        [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
                          <GatsbyImage image={node.data.target.gatsbyImageData} alt={node.data.target.title} className="news-body-image" />
                        ),
                      },
                    })}
                  </div>
                  {/* {event.date > new Date() ?   */}
                  {moment(event.date).format('YYYY-MM-DD') >
                  moment(new Date()).format('YYYY-MM-DD') ? (
                    <div>
                      <a href={event.cta.link} className="nav-link btn btn-mdb-color btn-sm-block mt-4" >
                        <FontAwesomeIcon icon={faCalendar} className="mr-2" /> {event.cta.linktext} </a>
                    </div>
                  ) : (
                    <button className="nav-link btn btn-mdb-color btn-sm-block mt-4">
                      {' '}
                      <FontAwesomeIcon icon={faBan} className="mr-2" /> Registration closed
                    </button>
                  )}
                </MDBCol>

                  <MDBCol lg="5" md="12">
                    {event.image && (
                      <GatsbyImage image={event.image.localFile.childImageSharp.gatsbyImageData} className="image-fluid rounded d-none d-lg-block" alt={event.altText} />
                    )}
                  </MDBCol>
              </MDBRow>
            </MDBContainer>
          </section>

          {event.section ? (
            <section className="bg-white-grey-gradient">
              <MDBContainer>
                <MDBRow>
                  {event.section.map((sections, index) => (
                     <MDBCol key={index}>
                        <EventSection section={sections} />
                    </MDBCol>
                  ))}
                </MDBRow>
              </MDBContainer>
            </section>
          ) : (
            ''
          )}

          {event.postSection ? (
            <section className={ event.postSection ? 'bg-white-grey-gradient' : 'bg-white' } >
              <MDBContainer>
                <MDBRow>
                  <MDBCol>
                    <div>
                      {renderRichText(event.postSection, {
                        renderNode: {
                          [BLOCKS.PARAGRAPH]: (node, children) => (
                            <p 
                              className="font-w-400 text-medium" 
                              style={{ whiteSpace: 'pre-wrap' }} 
                              > 
                              {children} 
                            </p>
                          ),
                          [BLOCKS.HEADING_3]: (node, children) => (
                            <h3 
                              className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-2" 
                              style={{ whiteSpace: 'pre-wrap' }} 
                              > 
                              {children} 
                            </h3>
                          ),
                          [INLINES.HYPERLINK]: (node, children) =>
                            node.data.uri.charAt(0) == '/' ? (
                              <Link to={node.data.uri}>{children}</Link>
                            ) : (
                              <a 
                                href={node.data.uri} 
                                className="effect" 
                                target="_blank" 
                                > 
                                {children} 
                              </a>
                            ),
                          [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
                            <GatsbyImage
                              image={node.data.target.gatsbyImageData}
                              alt={node.data.target.title}
                            />
                          ),
                        },
                      })}
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </section>
          ) : (
            ''
          )}

          {moment(event.date).format('YYYY-MM-DD') >
            moment(new Date()).format('YYYY-MM-DD') && (
            <section className="bg-white">
              <MDBContainer>
                <MDBRow>
                  <MDBCol lg="12">
                    <div>
                      <h3 className="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium"> {event.cta.title} </h3>
                      {renderRichText(event.cta.description, {
                        renderNode: {
                          [BLOCKS.PARAGRAPH]: (node, children) => (
                            <p 
                              className="font-w-400 text-medium" 
                              style={{ whiteSpace: 'pre-wrap' }} 
                              > 
                              {children} 
                            </p>
                          ),
                        },
                      })}
                      <a href={event.cta.link} className="nav-link btn btn-mdb-color mt-4" >
                        <FontAwesomeIcon icon={faCalendar} className="mr-2" /> {event.cta.linktext}
                      </a>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </section>
          )}

          {event.sponsor ? (
            <section className="bg-white">
              <MDBContainer>
                <MDBRow>
                  <MDBCol lg="12">
                    <div>
                      <h2 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-large"> {event.sponsor.title} </h2>
                      {renderRichText(event.sponsor.description, {
                          renderNode: {
                            [BLOCKS.PARAGRAPH]: (node, children) => (
                              <p 
                                className="font-w-400 text-medium" 
                                style={{ whiteSpace: 'pre-wrap' }} 
                                > 
                                {children} 
                              </p>
                            ),
                            [INLINES.HYPERLINK]: (node, children) =>
                              node.data.uri.charAt(0) == '/' ? (
                                <Link to={node.data.uri}>{children}</Link>
                              ) : (
                                <a href={node.data.uri} className="effect" target="_blank" > {children} </a>
                              ),
                            [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
                              <GatsbyImage
                                image={node.data.target.gatsbyImageData}
                                alt={node.data.target.title}
                                className="news-body-image py-3"
                              />
                            ),
                          },
                        }
                      )}
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </section>
          ) : (
            ''
          )}
        </Layout>
      </>
    )
  }
}

export default EventItemTemplate

export function Head({ data, pageContext }) {
  const event = data.contentfulEvent
  const pageUrl = '/events/' + event.slug + '/'
  const siteUrl = data.site.siteMetadata.siteUrl
  const heroImage= data.heroImage

  return (
    <SEO
      title={event.metaTitle}
      description={event.metaDescription} 
      url={pageUrl}       
      image={event.hero ? 'https:' + event.hero.file.url : siteUrl + heroImage.childImageSharp.gatsbyImageData.images.fallback.src}
      canonical={pageContext.canonical}                  
    />
  )
}

export const pageQuery = graphql`
  query contentfulEvents($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    contentfulEvent(slug: { eq: $slug }) {
      slug
      body {
        raw
      }
      cta {
        description {
          raw
        }
        title
        link
        linktext
      }
      metaTitle
      metaDescription 
      title
      subtitle
      date
      timezone
      duration
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 600, quality: 90)
          }
        }
      }
      altText
      hero {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 1920, quality: 90)
          }
        }
      file {
        url
      }
    }
      postSection {
        raw
        references {
          gatsbyImageData(
            quality: 90
            formats: [WEBP, AUTO]
            placeholder: BLURRED
          )
          ... on ContentfulAsset {
            contentful_id
            __typename
            file {
              url
            }
          }
        }
      }
      section {
        title
        subtitle
        imageonly
        image {
          gatsbyImageData(width: 2400, quality: 90)
        }
        altText
        description {
          raw
        }
        feature {
          description {
            raw
          }
          title
          subtitle
          info
          link
          linktext
        }
      }
      sponsor {
        description {
          raw
          references {
            gatsbyImageData(
              quality: 90
              formats: [WEBP, AUTO]
              placeholder: BLURRED
            )
            ... on ContentfulAsset {
              contentful_id
              __typename
              file {
                url
              }
              title
            }
          } 
        }       
        title
      }
    }
    heroImage: file(name: { eq: "events-default" }) {
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
  }
`
